'use client';

import Flag from 'components/Flag';
import MainHeader from 'components/HeaderNavigation';
import { TWContainer } from 'components/IngredientWrapper';
import { INotificationBannerProps, NotificationBanner } from 'components/NotificationBanner';
import dynamic from 'next/dynamic';
import Script from 'next/script';
import { ReactNode, useEffect, useState } from 'react';
import getSearchClient from 'server/repository/algolia/client';

import type {
  ICountryLanguage,
  IPopularSearch,
  ISearchOverlay,
} from 'components/HeaderNavigation/components/MobileNavigation';
import { MetaDataNextSEO, MetaDataType } from 'components/MetaDataNextSEO/MetaDataNextSEO';
import { useRouter } from 'next/router';
import NextNprogress from 'nextjs-progressbar';
import type {
  CountrySelectorFooter,
  GetNotificationBannerQuery,
} from 'server/repository/hygraph/generated/graphqlTypes';
import type { FooterNav } from 'types/Footer';
import type Navigation from 'types/Navigation';
import type { SearchCarousel } from 'types/SearchOverlay';
import type { MobileOnlyAsset } from 'types/headerNav';

import { CurveMask } from 'components/CurveMask';
import { JsonService } from 'service/json/jsonService';
import { TrackingService } from 'service/tracking/trackingService';
import { AVAILABLE_THEMES_TYPE } from '@/styles/constants';

const Footer = dynamic(() => import('components/Footer').then((m) => m.Footer), {
  ssr: true,
});

const Newsletter = dynamic(() => import('components/Forms').then((m: any) => m.Newsletter), {
  ssr: true,
}) as any;

interface RootLayoutClientProps {
  getHeaderNav: Navigation[];
  getMobileNavData: MobileOnlyAsset | null;
  popularSearchData: IPopularSearch;
  difficultyCopy: Record<string, string>;
  cardCarouselCMSData: SearchCarousel;
  globalMetadata: MetaDataType | null;
  usercentricsIds: string | null;
  locale: string;
  searchOverlayData: ISearchOverlay;
  footerNavData?: FooterNav;
  footerImage?: any;
  countrySelectorData?: CountrySelectorFooter[];
  newsletterData: any;
  contactUsUrl: string | null;
  children: ReactNode;
  recallData: GetNotificationBannerQuery['notificationBanners'][number];
  themeName?: AVAILABLE_THEMES_TYPE;
}

export default function RootLayoutClient({
  getHeaderNav,
  getMobileNavData,
  popularSearchData,
  difficultyCopy,
  globalMetadata,
  cardCarouselCMSData,
  usercentricsIds,
  locale,
  searchOverlayData,
  footerNavData,
  countrySelectorData,
  newsletterData,
  contactUsUrl,
  children,
  recallData,
  themeName,
}: RootLayoutClientProps) {
  const [searchOverlayDataExtended, setSearchOverlayDataExtended] = useState(searchOverlayData);
  useEffect(() => {
    TrackingService.pageValues(
      locale?.slice(3, 5) || '',
      locale?.slice(0, 2).toUpperCase() || '',
      window.location.pathname || '',
    );
  }, []);

  useEffect(() => {
    if (!searchOverlayDataExtended.searchClient) {
      const algoliaClient = getSearchClient();
      setSearchOverlayDataExtended({
        ...searchOverlayDataExtended,
        searchClient: algoliaClient,
      });
    }
  }, [searchOverlayData]);

  const footerCountrySelectorData = countrySelectorData?.[0];
  const localUsercentricIds = usercentricsIds && JsonService.parseJSON(usercentricsIds, {});
  const usercentricsId = (localUsercentricIds && locale && localUsercentricIds[locale]) || null;
  const countryLanguages = (countrySelectorData?.[0]?.countrySelectorList?.[0]?.language ??
    []) as ICountryLanguage[];

  const featuredCarousel = {
    carouselData: cardCarouselCMSData,
    cardData: [],
    copyDictionary: difficultyCopy,
  };

  // needs to be tested. track pageview on change
  const { asPath } = useRouter();
  useEffect(() => {
    TrackingService.pageview(window.location.href);
  }, [asPath]);

  return (
    <TWContainer>
      <NextNprogress
        color="#29D"
        startPosition={0.3}
        stopDelayMs={200}
        height={3}
        options={{ showSpinner: false }}
      />

      {/* Cookie Banner - Usercentrics */}
      {usercentricsId && (
        <Script
          async
          id="usercentrics-cmp"
          data-settings-id={usercentricsId}
          src="https://web.cmp.usercentrics.eu/ui/loader.js"
          strategy="afterInteractive"
        />
      )}

      {globalMetadata && <MetaDataNextSEO metaData={globalMetadata} locale={locale} />}

      <Flag locale={locale} url={contactUsUrl ?? ''} />
      <MainHeader
        headerNavData={getHeaderNav}
        searchData={searchOverlayDataExtended}
        mobileOnlyData={getMobileNavData}
        popularSearch={popularSearchData}
        featuredCardCarousel={featuredCarousel}
        logo={globalMetadata?.logo as { url: string }}
        countryLanguages={countryLanguages}
        locale={locale}
      />
      {recallData?.active && (
        <div className="max-w-6xl mx-auto">
          <NotificationBanner {...(recallData as unknown as INotificationBannerProps)} />
        </div>
      )}

      <div
        className="max-w-6xl mx-auto flex flex-col space-next-any-element"
        style={{ gap: 'var(--spacing-between-elements)' }}
      >
        {children}
      </div>
      {newsletterData && (
        <div className="mb-[-25vw] md:mb-[-20vw] lg:mb-[-200px]" data-print="hide">
          <Newsletter
            curveColor="var(--color-secondary)"
            data={newsletterData}
            themeName={themeName}
            trackingEvents={{ trackSubmitTrueEvent: TrackingService.trackSubmitTrueEvent }}
          />
        </div>
      )}
      {footerNavData && (
        <Footer
          footerImage={undefined}
          footerData={footerNavData}
          countrySelectorData={footerCountrySelectorData}
          isNewsletter={!!newsletterData}
          fillColorToken="var(--color-black)"
          textColorToken="var(--color-white)"
          trackingEvents={{
            about: TrackingService.footerAbout,
            social: TrackingService.footerFollowUs,
            more: TrackingService.footerMore,
            appStore: TrackingService.footerAppStore,
          }}
        />
      )}
      <CurveMask />
      {/* TO NOT REMOVE THIS `<CurveMask />` COMPONENT, this is a global configuration for the svg masks.
      This should be the only place for this */}
    </TWContainer>
  );
}
